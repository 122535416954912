// check if a variable is not undefined, null, or blank
var isset = function(variable) {
  return typeof(variable) !== 'undefined' && variable !== null && variable !== ''
          && variable != "null";
}

var now = function() {
  return 1 * new Date;
}

var guid = function() {
  return 'xxxxxxxx-'.replace(/[x]/g, function(c) {
      var r = Math.random()*36|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(36);
  }) + (1 * new Date()).toString(36);
}

// reduces all optional data down to a string
var optionalData = function(data) {
  if (isset(data) === false) {
    return '';
  } else if (typeof data === 'object') {
    // runs optionalData again to reduce to string in case something else was returned
    return optionalData(JSON.stringify(data));
  } else if (typeof data === 'function') {
    // runs the function and calls optionalData again to reduce further if it isn't a string
    return optionalData(data());
  } else {
    return String(data);
  }
}
var getPathInfo = function(path) {
  //  create a link in the DOM and set its href
  var link = document.createElement('a');
  link.setAttribute('href', path);

  //  return an easy-to-use object that breaks apart the path
  return {
    host: link.hostname,  //  'example.com'
    port: link.port,      //  12345
    path: link.pathname,  //  '/blog/foo/bar'
    protocol: link.protocol   //  'http:'
  }
}
var hash = function(s) {
  return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
}

var checkTextInHierarchy = function(element, regex) {
  let el = element;
  let levelsChecked = 0;
  
  while (el && el !== document.body && levelsChecked < 5) {
    const processedText = el.textContent
      .replace(/\s+/g, ' ')    // 合併多餘空白
      .replace(/[\u200B]/g, '') // 移除零寬空格
      .trim()
      .toLowerCase(); // 新增轉小寫

    if (processedText && regex.test(processedText)) {
      return true;
    }
    el = el.parentElement;
    levelsChecked++;
  }
  return false;
}

export default {
  isset: isset,
  now: now,
  guid: guid,
  optionalData: optionalData,
  getPathInfo: getPathInfo,
  hash: hash,
  checkTextInHierarchy: checkTextInHierarchy
}
